import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Drawer from 'lib/Drawer';
import { hideNotificationsSlider } from 'state/app-notifications/actions';
import { selectIsOpen } from 'state/app-notifications/selectors';

import Notifications from './Notifications';
import useNotifications from './Notifications/useNotifications';
import { ReactComponent as Icon } from './notifications-icon.svg';
import NotificationsFilters from './NotificationsFilters';

import styles from './AppNotifications.module.scss';

function AppNotifications() {
  const navigate = useNavigate();

  const { notifications, isLoading, fetchNotifications, meta, clearState } = useNotifications();

  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsOpen);

  const closeSlider = () => {
    dispatch(hideNotificationsSlider());
  };

  const handleNavigate = () => {
    closeSlider();
    navigate(`/settings/notifications`);
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={closeSlider} className={styles.drawer}>
      <div className={styles.header}>
        <div className={styles.title}>
          <Icon />
          <Typography variant="h5">Notifications</Typography>
        </div>
        <div>
          <IconButton aria-label="close" onClick={closeSlider} size="large">
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={isLoading ? styles['loading-body'] : styles.body}>
        <NotificationsFilters
          fetchNotifications={fetchNotifications}
          isLoading={isLoading}
          clearState={clearState}
          handleNavigate={handleNavigate}
        />
        <Notifications
          notifications={notifications}
          isLoading={isLoading}
          fetchNotifications={fetchNotifications}
          meta={meta}
        />
      </div>
    </Drawer>
  );
}

export default AppNotifications;
